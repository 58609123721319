import React, { useEffect } from "react"
import "./Website.scss"
import Hero from "./Components/Hero"
import SignUpButton from "./Components/SignUp"
import { isProduction } from "../util/server"
import { t } from "../intl"

export default function App () {
	useEffect(() => {
		if (isProduction) {
			window.gtag("js", new Date());
			window.gtag("config", "G-14KDWYYLN8");
		}
	})
	
	return <div className="Website">
		<Hero />
		<section className="creator">
			<div className="screenshot mobile">
				<div className="image"></div>
				<div className="text">
					<p>{ t("creator_copy_1") }</p>
					<p>{ t("creator_copy_mobile") }</p>
				</div>
			</div>
			<div className="screenshot desktop">
				<div className="image"></div>
				<div className="text">
					<p>{ t("creator_copy_2") }</p>
					<p>{ t("creator_copy_3") }</p>
				</div>
			</div>
			<p className="centered">{ t("creator_copy_4") }</p>
			<div className="link-qr-code"></div>
			<SignUpButton />
		</section>
		<section className="large-text">
			<div className="container">
				<div className="pretitle">{ t("for_developers") }</div>
				<h1>{ t("devs_main_title") }</h1>
				<p>{ t("devs_main_paragraph") }</p>
				<a className="orange arrow-right" href="https://stacky.app/stacky_developers" onTouchStart={e => e.preventDefault()}>{ t("learn_more") }</a>
			</div>
		</section>
	</div>
}