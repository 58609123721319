import React from "react"
import PropTypes from "prop-types"
import "./ListItem.scss"
import Action from "./Action"
import SmartImage from "./SmartImage"

function ListItem ({ stretchy, action, image, title, description, price, hideImages, hideDescription }) {
	const compact = !stretchy && hideDescription && hideImages
	return <li>
		<Action {...action} className={`ListItem ${stretchy && "stretchy"} ${compact && "compact"}`}>
			{ !stretchy && !hideImages && <SmartImage url={image} size="small" /> }
			<div className={`body ${hideDescription ? 'only-title' : ''}`}>
				<div className="title">{ title }</div>
				{ !hideDescription && price && <div className="price">{ price }</div> }
				{ !hideDescription && description && <div className="description">{ description }</div> }
			</div>
			{ !stretchy && <div className="icon icon-chevron-right" /> }
			{ stretchy && image && !hideImages && <SmartImage url={image} size="small" /> }
		</Action>
	</li>
}

ListItem.propTypes = {
	action: PropTypes.object,
	title: PropTypes.string,
	image: PropTypes.string,
	description: PropTypes.string,
	price: PropTypes.string,
	stretchy: PropTypes.bool
}

export default ListItem