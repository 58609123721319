import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import "./index.css"
import { CARD_SENT_TO_BACKGROUND_SCALE } from "./crossConstants"
import rootReducer from "./reducers"
import { createStore } from "redux"
import { Provider } from "react-redux"
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing"
import { isProduction } from "./util/server"

if (isProduction) {
	Sentry.init({
		dsn: "https://8ae9d6f8894a43de90f94b86a0c292f5@o511240.ingest.sentry.io/5616131",
		integrations: [
			new Integrations.BrowserTracing()
		],
		tracesSampleRate: 1.0
	})
}

export const store = createStore(
	rootReducer,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

document.body.style.setProperty("--CARD_SENT_TO_BACKGROUND_SCALE", CARD_SENT_TO_BACKGROUND_SCALE)

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>,
	document.getElementById("root")
)
