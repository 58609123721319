import { useState, useEffect, useCallback } from "react"
import { apiCache } from "../../../util/server";
import SmartText from "../../../util/smartText";
import processData from "../../../dataProcessors";
import { useLocation } from "react-router-dom";

export function useCardData ({ type, data }) {
	const [apiData, setApiData] = useState({})
	const location = useLocation()

	const getURLQueryParams = useCallback(() => {
		const params = new URLSearchParams(location.search)
		let paramObj = {}
		for (let value of params.keys()) {
			paramObj[value] = params.get(value)
		}
		return paramObj
	}, [location.search])

	const fetchAPIData = useCallback(async () => {
		const apiURL = new SmartText(data.apiURL)
		const finalUrl = apiURL.replaceTokens(getURLQueryParams())
		setApiData({})
		const receivedData = await apiCache.get(finalUrl)
		setApiData(receivedData.data)
	}, [getURLQueryParams, data.apiURL])

	const getData = () => {
		const process = data => processData(type, data)
		switch (data.dataSource) {
			case "staticWithUrlQuery":
				return process({
					...data,
					...getURLQueryParams()
				})
			case "urlQuery":
				return process(getURLQueryParams())
			case "api":
				return process(apiData)
			case "static":
			default:
				return process(data)
		}
	}

	useEffect(() => {
		if (data.apiURL) fetchAPIData()
	}, [fetchAPIData, data.apiURL])

	return getData()
}