import React from "react"
import Card, { getTitle } from "./Card"
import QRCode from "../GUI/QRCode"
import { useCardData } from "./hooks/useCardData"
import Title from "./Bits/Title"

export default function QRCodeCard (props) {
	const data = useCardData(props)
	console.log(props)

	const renderContents = () => {
		if (!data) return null
		const title = getTitle(props, data)
		return <>
			<Title>{ title }</Title>
			<div className="qr-code">
				{ data.qrText && <QRCode filename={title}>{ data.qrText }</QRCode> }
			</div>
			<div className="description">{ data.description }</div>
		</>
	}

	return <Card {...props} className="QRCodeCard">
		{ renderContents() }
	</Card>
}