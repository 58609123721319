/* eslint-disable react-hooks/rules-of-hooks */
const DEFAULT_STATE = {
	size: "small",
	preloadedResources: []
}

const useLargeUI = () => window.innerWidth > 780 && window.innerHeight > 550

const ui = (state = DEFAULT_STATE, action) => {
	switch (action.type) {
		case 'UI/REFRESH_SIZE':
			return {
				...state,
				size: useLargeUI() ? "large" : "small"
			}
		case 'UI/ADD_PRELOADED_RES':
			if (state.preloadedResources.includes(action.resource)) {
				return state
			}
			return {
				...state,
				preloadedResources: state.preloadedResources.concat([action.resource])
			}
		default:
			return state
	}
}

export default ui