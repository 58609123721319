import React from "react"
import { t } from "../../intl"
import "./SignUp.scss"

export default function SignUpButton () {
	return <div className="sign-up-log-in">
		<a className="sun-lamp-button" href="https://create.stacky.app/" onTouchStart={e => e.preventDefault()}>
			{ t("sign_up_or_log_in") }
		</a>
		<div className="pricing">{ t("accounts_for_free") }</div>
	</div>
}