export default {
	back: "Back",
	previous: "Previous",
	next: "Next",
	stack_not_found: "This stack was not found.",
	go_to_stacky: "Go to Stacky",



	// Non-translatable.

	twitter: "Twitter",
	facebook: "Facebook",
	youtube: "YouTube",
	instagram: "Instagram",
	stack: "Stack",
	telegram: "Telegram",
	whatsapp: "WhatsApp",


	
	// Shared.

	phone: "Phone",
	main_phone: "Main phone",
	second_phone: "Second phone",
	address: "Address",
	email: "Email",
	external_link: "External link",
	
	
	
	// Website.
	
	sub_logo_title: "Create micro websites.",
	turn_your: "Create a digital",
	into_cards: "within minutes",
	restaurant_menu: "Restaurant menu",
	product_catalog: "Product catalog",
	personal_card: "Business card",
	product_instructions: "Guide manual",
	click_to_try_this_stack: "Click to try this stack",

	scan_qr_code: "Scan QR code to",
	see_menu: "see the menu",
	see_catalog: "see the catalog",
	see_personal_card: "see the card",
	see_instructions: "read the manual",

	sign_up_or_log_in: "Sign up or log in",
	accounts_for_free: "Create an account for free",

	creator_copy_1: "Easy to use visual editor.",
	creator_copy_2: "Create cards and connect them.",
	creator_copy_3: "Preview in real time.",
	creator_copy_mobile: "Edit on mobile and desktop.",
	creator_copy_4: "Publish and instantly get a link and a QR code to share.",

	for_developers: "For developers",
	devs_main_title: "Front-end you don't have to maintain.",
	devs_main_paragraph: "Leverage Stacky's UI and UX by connecting your back-end to cards.",
	learn_more: "Learn more"
}