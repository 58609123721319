import React from "react"
import Card from "./Card"
import Slides from "../GUI/Slides"
import { CARD_SENT_TO_BACKGROUND_SCALE } from "../../crossConstants"
import SmartImage from "../GUI/SmartImage"
import { useCardData } from "./hooks/useCardData"
import Title from "./Bits/Title"

export default function ImagesCard (props) {
	const data = useCardData(props)

	const renderContents = () => {
		if (!data) return null
		const images = (data.images || []).filter(image => image && image.url)
		return <>
			<div className="contents">
				<Title props={props} data={data} />
				{ images.length > 0 && <div className="slides-wrapper">
					<Slides ratio="auto" initialWidthCoef={props.backwardAnimationIn ? CARD_SENT_TO_BACKGROUND_SCALE : 1}>
						{ images.map(image => <SmartImage key={image.url} url={image.url} size="large" fit="contain" />) }
					</Slides>
				</div> }
			</div>
		</>
	}

	return <Card {...props} className="ImagesCard">
		{ renderContents() }
	</Card>
}