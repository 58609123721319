export function useCardToItemProps ({ getCard }) {
	return (cardId) => {
		if (!cardId) {
			return null
		}
		const card = getCard(cardId)
		if (!card) {
			return null
		}
		const data = card.data
		return {
			title: data.title || card.name,
			image: data.banner || data.image || (Array.isArray(data.images) && data.images[0] ? data.images[0].url : null),
			description: data.description || (data.body && data.body.substr(0, 90)), // TODO: Smartly cut off around a blank space.
			action: {
				type: "card",
				cardId
			}
		}
	}
}