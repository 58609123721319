import React, { Component } from "react"
import "./Slides.scss"
import "./Hero.scss"
import { t, targetLanguage } from "../../intl"
import SignUpButton from "./SignUp"

export default class Hero extends Component {
	state = {
		currentSlide: 0,
		currentAngleStep: 0,
		transitioningSlidesShort: false,
		transitioningSlides: false
	}

	goNextSlide () {
		const { currentSlide, currentAngleStep } = this.state
		this.setState({
			currentSlide: currentSlide >= 3 ? 0 : currentSlide + 1,
			currentAngleStep: currentAngleStep + 1,
			transitioningSlides: true,
			transitioningSlidesShort: true
		})
		clearTimeout(this.transitioningTimeout)
		clearTimeout(this.transitioningTimeoutShort)
		this.transitioningTimeout = setTimeout(() => this.setState({ transitioningSlides: false }), 1200)
		this.transitioningTimeoutShort = setTimeout(() => this.setState({ transitioningSlidesShort: false }), 300)
	}

	onNextSlide () {
		if (this.onNextSlideTimeout) {
			return
		}
		this.goNextSlide()
		this.stopAutoSlideInterval()
		this.waitToResumeAutoSlide()
		this.onNextSlideTimeout = setTimeout(() => this.onNextSlideTimeout = null, 150)
	}

	stopAutoSlideInterval () {
		clearInterval(this.autoSlideInterval)
		clearTimeout(this.waitToResumeAutoSlideTimeout)
	}

	startAutoSlideInterval () {
		this.autoSlideInterval = setInterval(() => this.goNextSlide(), 6500)
	}

	waitToResumeAutoSlide () {
		this.waitToResumeAutoSlideTimeout = setTimeout(() => this.startAutoSlideInterval(), 6000)
	}

	componentDidMount () {
		requestAnimationFrame(() => {
			this.setState({
				currentAngleStep: 4,
				transitioningSlides: true
			})
			this.transitioningTimeout = setTimeout(() => this.setState({ transitioningSlides: false }), 1200)
			this.startAutoSlideInterval()
		})
	}

	componentWillUnmount () {
		clearTimeout(this.transitioningTimeout)
		clearTimeout(this.transitioningTimeoutShort)
		this.stopAutoSlideInterval()
	}

	render () {
		const longLang = targetLanguage !== "en"
		const { currentSlide, currentAngleStep, transitioningSlides, transitioningSlidesShort } = this.state
		return <section className="Hero">
			<div className="logo">
				<img className="stacky" src="/website/images/stacky@2x.png" alt="Stacky" />
				<div className="sub-logo-title">{ t("sub_logo_title") }</div>
			</div>
			<div className="columns">
				<div className="column">
					<Slides type="flips" className="cards" transitioning={transitioningSlidesShort} currentSlide={currentSlide} currentAngleStep={currentAngleStep}>
						<a className="image" href="https://stacky.app/the_restaurant" target="_blank" rel="noopener noreferrer">{ t("restaurant_menu") }</a>
						<a className="image" href="https://stacky.app/personal_card" target="_blank" rel="noopener noreferrer">{ t("personal_card") }</a>
						<a className="image" href="https://stacky.app/catalog" target="_blank" rel="noopener noreferrer">{ t("product_catalog") }</a>
						<a className="image" href="https://stacky.app/instructions" target="_blank" rel="noopener noreferrer">{ t("product_instructions") }</a>
					</Slides>
					<div className="click-hint">{ t("click_to_try_this_stack") }</div>
				</div>
				<div className="column">
					<div className="copy">
						<div className="title-pre-after">{ t("turn_your") }</div>
						<Slides type="text" className={`titles condensed ${longLang && "wider"}`} currentSlide={currentSlide} currentAngleStep={currentAngleStep} steps={true} onClick={this.onNextSlide.bind(this)}>
							<div className={`text ${longLang && "tight"}`}>{ t("restaurant_menu") }</div>
							<div className="text">{ t("personal_card") }</div>
							<div className={`text ${longLang && "tight"}`}>{ t("product_catalog") }</div>
							<div className="text tight">{ t("product_instructions") }</div>
						</Slides>
					</div>
					<div className="scan-qr">
						<div className={`qr-code ${transitioningSlides && "transitioning"}`}>
							<div className="corner top-left"></div>
							<div className="corner top-right"></div>
							<div className="corner bottom-right"></div>
							<div className="corner bottom-left"></div>
							<Slides type="fades" className="qr" currentSlide={currentSlide} currentAngleStep={currentAngleStep}>
								<div className="image"></div>
								<div className="image"></div>
								<div className="image"></div>
								<div className="image"></div>
							</Slides>
						</div>
						<div className="legend">
							<div className="soft">{ t("scan_qr_code") }</div>
							<div className="strong">
								<Slides type="text" className="labels" currentSlide={currentSlide} currentAngleStep={currentAngleStep}>
									<div className="text">{ t("see_menu") }</div>
									<div className="text">{ t("see_personal_card") }</div>
									<div className="text">{ t("see_catalog") }</div>
									<div className="text">{ t("see_instructions") }</div>
								</Slides>
							</div>
						</div>
					</div>
					<SignUpButton />
				</div>
			</div>
		</section>
	}
}

function Slides ({ children, type, className, steps, transitioning, currentSlide, currentAngleStep, onClick }) {
	const becameInactiveIndex = currentSlide <= 0 ? 3 : currentSlide - 1
	const transform = type === "images" ? "" : (type === "text" ? "rotateX" : "")
	const rotation = (type === "images" ? -1 : 1) * currentAngleStep * 90

	return <div className={`Slides ${type} ${className} ${transitioning && "transitioning"}`} onClick={onClick}>
		<div className="scaler">
			<div className="slides-container" style={{ transform: `${transform}(${rotation}deg)` }}>
				{
					children.map((slide, i) => <div key={i} className={`slide ${currentSlide === i && "active"} ${becameInactiveIndex === i && "became-inactive"}`}>{ slide }</div>)
				}
			</div>
		</div>
		{ steps && <div className="steps">
			{
				children.map((slide, i) => <div key={i} className={`step ${currentSlide === i && "active"}`}></div>)
			}
		</div> }
	</div>
}