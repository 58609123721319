import React from "react"
import "./Body.scss"

const textToParagraphs = (text) => {
	if (!text) {
		return []
	}
	return text.split("\n")
}

export default function Body ({ bodyHTML, body }) {
	if (!body && !bodyHTML) {
		return null
	}

	if (bodyHTML) {
		return <div className="body Body" dangerouslySetInnerHTML={{__html: bodyHTML }}></div>
	}

	return <div className="body Body">
		{ textToParagraphs(body).map((p, i) => <p key={i}>{ p }</p>) }
	</div>
}