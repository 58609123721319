import React from "react"
import Card from "./Card"
import DefinitionItem from "../GUI/DefinitionItem"
import { useCardData } from "./hooks/useCardData"
import Title from "./Bits/Title"
import Body from "./Bits/Body"

export default function ContactCard (props) {
	const data = useCardData(props)

	const renderContents = () => {
		if (!data) return null
		return <>
			<div className="contents">
				<Title props={props} data={data} />
				<Body {...data} />
				{ data.fields &&
					<dl className="definition-list">
						{ data.fields.map((props, i) => {
							return <DefinitionItem
								key={`${i}-${props.name || "untitled"}`}
								{...props}
							/>
						})}
					</dl>
				}
			</div>
		</>
	}

	return <Card {...props} className="ContactCard with-max-width with-listing">
		{ renderContents() }
	</Card>
}