import React, { Component } from "react"
import PropTypes from "prop-types"
import "./App.scss"
import "./sass/animations_global.sass"
import "./sass/colors_global.scss"
import { Switch, Route, withRouter } from "react-router-dom"
import StackViewer from "./Components/StackViewer"
import { refreshUISize } from "./actions"
import { connect } from "react-redux"
import DebugOverlay from "./Components/DebugOverlay"
import Website from "./Website/Website"

const debugOverlay = false

const refreshVH = () => document.body.style.setProperty("--vh", `${window.innerHeight * 0.01}px`)

class App extends Component {
	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired
	}

	constructor (props) {
		super(props)
		this.stackKey = new URLSearchParams(window.location.search).get("d")
		this.state = {
			colorScheme: this.getPreferedColorScheme()
		}
		this.refreshUI = this.refreshUI.bind(this)
		this.setBodyColorScheme(this.state.colorScheme)
		window.matchMedia("(prefers-color-scheme: light)").addListener(e => e.matches && this.refreshColorScheme())
		window.matchMedia("(prefers-color-scheme: dark)").addListener(e => e.matches && this.refreshColorScheme())
		window.addEventListener("resize", this.refreshUI)
		this.refreshUI()
	}
	
	componentDidMount () {
		if (this.shouldRenderWebsite()) {
			console.log("AAA")
			document.body.classList.add("website")
		}
	}

	componentDidUpdate (_prevProps, prevState) {
		if (this.state.colorScheme !== prevState.colorScheme) {
			this.setBodyColorScheme(this.state.colorScheme)
		}
	}

	getPreferedColorScheme () {
		return matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"
	}

	refreshColorScheme () {
		this.setState({ colorScheme: this.getPreferedColorScheme() })
	}

	refreshUI () {
		refreshVH()
		this.props.refreshUISize()
	}

	setBodyColorScheme (colorScheme) {
		const { body } = document
		body.classList.remove("color-scheme-light")
		body.classList.remove("color-scheme-dark")
		body.classList.add(`color-scheme-${colorScheme}`)
	}

	getCard (id) {
		return this.state.cards.find(cardData => cardData.id === id)
	}
	
	shouldRenderWebsite () {
		const isEmbed = window.parent !== window
		const isRootPath = window.location.pathname.length <= 1
		return !isEmbed && isRootPath
	}

	render () {
		if (this.shouldRenderWebsite()) {
			return <Website />
		}
		
		let classNames = [
			"App",
			`color-scheme-${this.state.colorScheme}`,
			`${this.props.uiSize}-ui`
		]
		return (
			<div className={classNames.join(" ")}>
				{ debugOverlay && <DebugOverlay />}
				{ this.props.uiSize &&
					<Switch>
						<Route path={[
							"/:stackId/:cardId",
							"/:stackId",
							"/"
						]}>
							<StackViewer />
						</Route>
					</Switch>
				}
			</div>
		)
	}
}

const mapStateToProps = state => ({
	uiSize: state.ui.size
})

const mapDispatchToProps = { refreshUISize }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App))
