/* eslint-disable no-useless-escape */

export default class SmartText {
	constructor (text) {
		this.text = text
	}

	isEmpty () {
		return !this.text
	}

	isHttpLink () {
		if (this.isEmpty()) {
			return false
		}
		const text = this.text.toLowerCase()
		if (this._isHttpLink === undefined) {
			this._isHttpLink = text.indexOf('http://') === 0 || text.indexOf('https://') === 0
		}
		return this._isHttpLink
	}

	getURL () {
		return this.isHttpLink() ? new URL(this.text) : null
	}
	
	replaceTokens (tokenValues) {
		if (!this.text) {
			return this.clone()
		}
		return Object.keys(tokenValues).reduce((smartText, key) => (
			smartText.replaceAll(`{${key}}`, tokenValues[key])
		), this)
	}

	// Returns a shortened version of the string, cutting only between words and adding ellipsis at the end, if trimming occurred.
	ellipsis (length) {
		if (this.text.length <= length + 3) {
			return this.text
		}
		return this.text.substring(0, this.text.lastIndexOf(" ", length)) + "…"
	}

	toString () {
		return this.text
	}

	clone () {
		return new SmartText(this.text)
	}

	replaceAll (find, replace) {
		if (!this.text) {
			return this.clone()
		}
		return new SmartText(this.text.replace(new RegExp(escapeRegExp(find), 'g'), replace))
	}
}

const escapeRegExp = (string) => {
	return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')
}