export const setStack = payload => ({
	type: 'STACK/SET',
	payload
})

export const setStackCards = payload => ({
	type: 'STACK/SET_CARDS',
	payload
})

export const setStackHome = payload => ({
	type: 'STACK/SET_HOME',
	payload
})

export const refreshUISize = () => ({
	type: 'UI/REFRESH_SIZE'
})

export const setUIPreloadedRes = resource => ({
	type: 'UI/ADD_PRELOADED_RES',
	resource
})

