/* eslint-disable default-case */
import React from "react"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
import "./Action.scss"
import { Link, useRouteMatch } from "react-router-dom"
import { isAppleDevice } from "../../util/userAgent"
import SmartText from "../../util/smartText"
import { compact } from "lodash-es"

const mapsURL = isAppleDevice
	? "maps://maps.apple.com/"
	: "maps://maps.google.com/"

const processProps = (props) => {
	let adjustments = {}
	switch (props.type) {
		case "address":
			adjustments = {
				value: props.value || compact([props.street, props.city, props.state, props.country]).join(", ")
			}
			break
		case "website_profile":
			let getWebsiteProfileURL = ({ namePreset, value }) => {
				if ((new SmartText(value)).isHttpLink()) {
					return value
				}
				switch (namePreset) {
					case "twitter":
						return `https://twitter.com/${value}`
					case "instagram":
						return `https://www.instagram.com/${value}/`
					case "facebook":
						return `https://www.facebook.com/${value}`
					case "youtube":
						return `https://www.youtube.com/${value}`
				}
			}
			adjustments = { url: getWebsiteProfileURL(props) }
			break
		case "messaging":
			let getMessagingURL = ({ namePreset, value }) => {
				if (!value) {
					return null
				}
				switch (namePreset) {
					case "telegram":
						return `https://t.me/${value}`
					case "whatsapp":
						const numbersOnly = value.replace(/[^0-9]/g, "")
						return `https://wa.me/${numbersOnly}`
					default:
						return null
				}
			}
			adjustments = { url: getMessagingURL(props) }
			break
		case "external_link":
			adjustments = { url: props.value }
			break
	}
	return {
		...props,
		...adjustments
	}
}

function Action (props) {
	const { className, children, type, url, cardId, params, value, subject } = processProps(props)
	const routeMatch = useRouteMatch({
		path: "/:stackId",
		strict: false,
		sensitive: false
	})
	const homeCardId = useSelector(state => state.stack.home)

	const getCardLink = () => {
		let path = `/${routeMatch.params.stackId}`
		if (homeCardId !== cardId) {
			path += `/${cardId}`
		}
		if (params) {
			path += `?${params}`
		}
		return path
	}

	const getClassNames = (additionalClassNames) => {
		return `Action ${className} ${additionalClassNames}`
	}

	switch (type) {
		case "card":
			return cardId
				? <Link className={getClassNames("with-action")} to={getCardLink()} tabIndex="0">{ children }</Link>
				: <div className={getClassNames("disabled")}>{ children }</div>
		case "external_link":
		case "external":
			return <a className={getClassNames("with-action")} href={url} rel="external noopener noreferrer" target="_blank" tabIndex="0">{ children }</a>
		case "phone":
			return <a className={getClassNames("with-action")} href={`tel://${value}`} tabIndex="0">{ children }</a>
		case "address":
			return <a className={getClassNames("with-action")} href={`${mapsURL}?address=${encodeURI(value)}`} tabIndex="0">{ children }</a>
		case "email":
			const subjectString = subject ? `?subject=${encodeURIComponent(subject)}` : ""
			return <a className={getClassNames("with-action")} href={`mailto:${value}${subjectString}`} tabIndex="0">{ children }</a>
		case "website_profile":
		case "messaging":
			return <a className={getClassNames("with-action")} href={url} rel="external noopener noreferrer" target="_blank" tabIndex="0">{ children }</a>
		case "none":
		default:
			return <div className={getClassNames("without-action")}>{ children }</div>
	}
}

Action.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	url: PropTypes.string,
	cardId: PropTypes.string,
	params: PropTypes.string
}

export default Action