import React, { useState, useEffect } from "react"
import './Card.scss'
import LoadingIndicator from "../GUI/LoadingIndicator"

export default function Card (props) {
	const [ waitingApiData, setWaitingApiData ] = useState(props.apiURL)
	const totalCTAs = props.data.callsToAction
		? props.data.callsToAction.length
		: (props.totalCTAs || 0)

	const getClassName = () => {
		const classNames = ["Card"]
		if (props.isHome) classNames.push("home")
		if (totalCTAs > 0) classNames.push("with-cta")
		return classNames.concat(props.className).join(" ")
	}

	const getStyle = () => {
		const styles = {}
		if (totalCTAs > 0) styles['--cta-count'] = totalCTAs
		return styles
	}

	useEffect(() => {
		setWaitingApiData(false)
	}, [props.data])

	return <div className={getClassName()} style={getStyle()}>
		{ props.apiURL && <div className="center-overlay">
			<LoadingIndicator visible={waitingApiData} />
		</div> }
		{ props.children }
	</div>
}

export const getTitle = (props, data) => data.title || props.name

export const textToParagraphs = (text) => {
	if (!text) {
		return []
	}
	return text.split("\n")
}