import React from "react"
import SmartImage from "../../GUI/SmartImage"
import Title from "./Title"

export default function LogoTitle ({ children, props, data }) {
	if (!data.logo) {
		return <Title props={props} data={data}>{ children }</Title>
	}

	return <div className="LogoTitle">
		<SmartImage url={data.logo} size="large" fit="contain" title={children} />
	</div>
}