import React from "react"
import SmartImage from "../../GUI/SmartImage"
import Title from "./Title"

export default function BannerTitle ({ children, props, data }) {
	if (!data.banner) {
		return <Title props={props} data={data}>{ children }</Title>
	}

	return <div className="banner">
		<div className="background">
			<SmartImage url={data.banner} size="large" />
		</div>
		<div className="container">
			<Title props={props} data={data} trim={30}>{ children }</Title>
		</div>
	</div>
}