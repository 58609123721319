import React from "react"
import Card from "./Card"
import SmartText from "../../util/smartText"
import { useCardData } from "./hooks/useCardData"

export default function VideoCard (props) {
	const data = useCardData(props)

	const getSrc = () => {
		const shareLink = new SmartText(data.shareLink)
		if (!shareLink.isHttpLink()) {
			return null
		}
		let videoId
		const url = shareLink.getURL()
		if (url.host.includes('youtube') || url.host.includes('youtu.be')) {
			videoId = url.searchParams.get('v')
			if (!videoId) {
				videoId = url.pathname.split('/')[1]
			}
		}
		if (videoId) {
			return `https://www.youtube-nocookie.com/embed/${videoId}`
		}
		return null
	}

	const renderContents = () => {
		if (!data) return null
		const src = getSrc()
		return <>
			{ src && <iframe
				title="Embed video"
				frameBorder="0"
				src={src}
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			></iframe> }
		</>
	}

	return <Card {...props} className="VideoCard">
		{ renderContents() }
	</Card>
}