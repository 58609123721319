import React from "react"
import Card from "./Card"
import ListItem from "../GUI/ListItem"
import Body from "./Bits/Body"
import CallsToAction from "./Bits/CallsToAction"
import { useCardData } from "./hooks/useCardData"
import BannerTitle from "./Bits/BannerTitle"
import { useCardToItemProps } from "./hooks/useCardToItemProps"

export default function CardListCard (props) {
	const data = useCardData(props)
	const itemProps = useCardToItemProps(props)

	// [WET] renderItems
	const renderItems = () => {
		if (!data || !data.items) return null
		const hideImages = !data.items.some(item => {
			const { banner, image } = itemProps(item.cardId) || {}
			return banner || image
		})
		if (!data.items) {
			return null
		}
		return <ul className="items-list">
			{ data.items.map((item, i) => {
				const card = itemProps(item.cardId)
				return card
					? <ListItem key={`${i}-${card.title || "untitled"}`} {...card} index={i} hideImages={hideImages} hideDescription={data.titlesOnly} />
					: null
			})}
		</ul>
	}

	const renderContents = () => {
		if (!data) return null
		return <>
			<div className="contents">
				<BannerTitle props={props} data={data} />
				<Body {...data} />
				{ renderItems() }
			</div>
			<CallsToAction actions={data.callsToAction} />
		</>
	}

	return <Card {...props} className="CardListCard with-max-width with-listing">
		{ renderContents() }
	</Card>
}