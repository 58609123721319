import React from "react"
import Card from "./Card"
import ListItem from "../GUI/ListItem"
import Body from "./Bits/Body"
import CallsToAction from "./Bits/CallsToAction"
import { useCardData } from "./hooks/useCardData"
import { useCardToItemProps } from "./hooks/useCardToItemProps"
import LogoTitle from "./Bits/LogoTitle"

export default function LandingCard (props) {
	const data = useCardData(props)
	const contactCard = data.contactCard || {}
	const itemProps = useCardToItemProps(props)
	const contactCardProps = itemProps(contactCard.cardId)

	// [WET] renderItems
	const renderItems = () => {
		if (!data || !data.items) return null
		const hideImages = !data.items.some(item => {
			const { banner, image } = itemProps(item.cardId) || {}
			return banner || image
		})
		if (!data.items) {
			return null
		}
		return <ul className="items-list">
			{ data.items.map((item, i) => {
				const card = itemProps(item.cardId)
				return card
					? <ListItem key={`${i}-${card.title || "untitled"}`} {...card} index={i} hideImages={hideImages} hideDescription={data.titlesOnly} />
					: null
			})}
		</ul>
	}

	const renderContents = () => {
		if (!data) return null
		return <>
			<div className="contents">
				<LogoTitle props={props} data={data}>{ props.name }</LogoTitle>
				<Body {...data} />
				{ renderItems() }
			</div>
			{ contactCardProps &&
				<CallsToAction actions={[{ action: { type: "card", cardId: contactCard.cardId }, label: contactCardProps.title }]} />
			}
		</>
	}

	const cardProps = {
		...props,
		totalCTAs: contactCardProps ? 1 : 0
	}

	return <Card {...cardProps} className="LandingCard with-max-width with-listing">
		{ renderContents() }
	</Card>
}