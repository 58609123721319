import axios from "axios"

export const isProduction = process.env.NODE_ENV === "production" && !new URLSearchParams(window.location.search).has("useDevServer")
const isStaging = window.location.host === "staging.stacky.app"

export const Server_URL = isStaging
	? "https://stacky-staging.herokuapp.com"
	: (isProduction
		? "https://api.stacky.app"
		: "http://192.168.1.47:9292"
	)

export const axios_instance = axios.create({
	baseURL: Server_URL
})

class APICache {
	constructor () {
		this.axiosInstance = axios.create()
		this.cache = {}
	}

	async get (url) {
		if (!this.cache[url]) {
			const promise = await this.axiosInstance.get(url)
			this.cache[url] = promise.data
		}
		return this.cache[url]
	}
}

export const apiCache = new APICache()