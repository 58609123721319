const DEFAULT_STATE = {
	home: null,
	cards: []
}

const stack = (state = DEFAULT_STATE, action) => {
	switch (action.type) {
		case 'STACK/SET':
			return {
				...state,
				...action.payload
			}
		case 'STACK/SET_HOME':
			return {
				...state,
				home: action.payload
			}
		case 'STACK/SET_CARDS':
			return {
				...state,
				cards: action.payload
			}
		default:
			return state
	}
}

export default stack