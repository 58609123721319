import React from "react"
import PropTypes from "prop-types"
import "./DefinitionItem.scss"
import Action from "./Action"

function DefinitionItem ({ stretchy, action, name, value, icon }) {
	return <Action {...action} className={`DefinitionItem ${stretchy && "stretchy"}`}>
		{ icon && <div className={`icon icon-${icon}`}></div> }
		<div className="text">
			<dt className="name">{ name }</dt>
			<dd className="value">{ value }</dd>
		</div>
	</Action>
}

DefinitionItem.propTypes = {
	action: PropTypes.object,
	name: PropTypes.string,
	value: PropTypes.string,
	stretchy: PropTypes.bool
}

export default DefinitionItem