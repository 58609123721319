import React from "react"
import Card from "./Card"
import { useCardData } from "./hooks/useCardData"

// https://embed.music.apple.com/us/album/miyabi/310968108
// https://music.apple.com/us/album/%E5%96%AE%E8%BA%AB%E6%A5%B5%E5%93%81/875198460

export default function MusicAlbumCard (props) {
	const data = useCardData(props)

	const getSrc = () => {
		if (!data.shareLink) {
			return null
		}
		let artist, albumId
		let region = "us"
		if (data.shareLink.toLowerCase().indexOf("://music.apple.com") < 7) {
			let urlParts = data.shareLink.split("/")
			let albumIndex = urlParts.indexOf("album")
			region = urlParts[albumIndex - 1]
			artist = urlParts[albumIndex + 1]
			albumId = urlParts[albumIndex + 2]
		}
		if (artist && albumId) {
			return `https://embed.music.apple.com/${region}/album/${artist}/${albumId}`
		}
		return null
	}

	const renderContents = () => {
		if (!data) return null
		const src = getSrc()
		return <>
			{ src && <iframe
				title="Apple Music Album"
				allow="autoplay *; encrypted-media *;"
				frameBorder="0"
				sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
				src={src}
			></iframe> }
		</>
	}

	return <Card {...props} className="MusicAlbumCard">
		{ renderContents() }
	</Card>
}