import React from "react"
import { getTitle } from "../Card"
import SmartText from "../../../util/smartText"

export default function Title ({ children, props, data, trim }) {
	let text = children || getTitle(props, data)
	if (!text) {
		return null
	}
	if (trim) {
		text = new SmartText(text).ellipsis(30)
	}
	return <h1>{ text }</h1>
}