import React from "react"
import Card from "./Card"
import ListItem from "../GUI/ListItem"
import CallsToAction from "./Bits/CallsToAction"
import { useCardData } from "./hooks/useCardData"
import BannerTitle from "./Bits/BannerTitle"

export default function PricingListCard (props) {
	const data = useCardData(props)

	const renderContents = () => {
		if (!data) return null
		return <>
			<div className="contents">
				<BannerTitle props={props} data={data} />
				{ data.items && <ul className="items-list">
					{ data.items.map((item, i) => 
						<ListItem key={`${i}-${item.title || "untitled"}`} {...item} stretchy={true} />
					)}
					</ul>
				}
			</div>
			<CallsToAction actions={data.callsToAction} />
		</>
	}

	return <Card {...props} className="PricingListCard with-max-width with-listing">
		{ renderContents() }
	</Card>
}