import React from "react"
import Card from "./Card"
import ListItem from "../GUI/ListItem"
import CallsToAction from "./Bits/CallsToAction"
import { useCardData } from "./hooks/useCardData"
import Title from "./Bits/Title"

export default function ItemListCard (props) {
	const data = useCardData(props)

	const renderContents = () => {
		if (!data) return null
		return <>
			<div className="contents">
				<Title props={props} data={data} />
				{ data.items &&
					<ul className="items-list">
						{ data.items.map((item, i) => 
							<ListItem key={`${i}-${item.title || "untitled"}`} {...item} />
						)}
					</ul>
				}
			</div>
			<CallsToAction actions={data.callsToAction} />
		</>
	}

	return <Card {...props} className="ItemsListCard with-max-width with-listing">
		{ renderContents() }
	</Card>
}