import React from "react"
import Card from "./Card"
import Slides from "../GUI/Slides"
import { CARD_SENT_TO_BACKGROUND_SCALE } from "../../crossConstants"
import SmartImage from "../GUI/SmartImage"
import Body from "./Bits/Body"
import CallsToAction from "./Bits/CallsToAction"
import { useCardData } from "./hooks/useCardData"
import Title from "./Bits/Title"
// import ContactClip from "../Clips/ContactClip"

export default function DetailsCard (props) {
	const data = useCardData(props)

	const renderContents = () => {
		if (!data) return null
		const images = (data.images || []).filter(image => image && image.url)
		return <>
			<div className="contents">
				<Title props={props} data={data} />
				<div className="auto-columns">
					{ images.length > 0 && <div className="slides-wrapper">
						<Slides ratio={data.imagesRatio || "wide"} initialWidthCoef={props.backwardAnimationIn ? CARD_SENT_TO_BACKGROUND_SCALE : 1}>
							{ images.map(image => <SmartImage key={image.url} url={image.url} size="medium" />) }
						</Slides>
					</div> }
					{/* <ContactClip
						type="phone"
						cardProps={props.getCard("31acad5dc5647b14b17133158cb4430c")}
					/> */}
					<Body {...data} />
				</div>
			</div>
			<CallsToAction actions={data.callsToAction} />
		</>
	}

	return <Card {...props} className="DetailsCard">
		{ renderContents() }
	</Card>
}