import React, { useRef, useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import "./Slides.scss"
import { t } from "../../intl"
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill()

const RATIOS = {
	"square": 1.0,
	"wide": 0.8
}

function Slides ({ ratio, children, initialWidthCoef }) {
	const totalSlides = children.length
	const multipleSlides = totalSlides > 1
	const [ observedSlide, setObservedSlide ] = useState(0)
	const [ targetSlide, setTargetSlide ] = useState(0)
	const [ width, setWidth ] = useState(0)
	const targetScroll = useRef(0)
	const independentTimeout = useRef()
	const lastWidth = useRef(width)
	const wrapper = useRef()
	const containerRef = useRef()

	const getWrapperWidth = useCallback(() => {
		return wrapper.current.getBoundingClientRect().width
	}, [wrapper])

	const onWindowResize = useCallback(() => {
		setWidth(getWrapperWidth())
	}, [getWrapperWidth])

	const canGoPrevious = useCallback(() => {
		return observedSlide > 0
	}, [observedSlide])
	
	const canGoNext = useCallback(() => {
		return observedSlide < totalSlides - 1
	}, [observedSlide, totalSlides])

	const previousSlide = useCallback(() => {
		if (canGoPrevious()) {
			setTargetSlide(observedSlide - 1)
		}
	}, [canGoPrevious, setTargetSlide, observedSlide])

	const nextSlide = useCallback(() => {
		if (canGoNext()) {
			setTargetSlide(observedSlide + 1)
		}
	}, [canGoNext, setTargetSlide, observedSlide])

	const onKeyDown = useCallback((e) => {
		if (e.key === "ArrowLeft") {
			previousSlide()
			e.preventDefault()
		}
		if (e.key === "ArrowRight") {
			nextSlide()
			e.preventDefault()
		}
	}, [previousSlide, nextSlide])

	useEffect(() => {
		setWidth(getWrapperWidth() / (initialWidthCoef || 1))
		window.addEventListener("resize", onWindowResize)
		window.addEventListener("keydown", onKeyDown)
		return () => {
			window.removeEventListener("resize", onWindowResize)
			window.removeEventListener("keydown", onKeyDown)
		}
	}, [wrapper, initialWidthCoef, getWrapperWidth, onWindowResize, onKeyDown])

	useEffect(() => {
		const lastSlide = totalSlides - 1
		if (observedSlide > lastSlide) {
			setObservedSlide(lastSlide)
		}
	}, [totalSlides, observedSlide, setObservedSlide])

	const onScroll = (e) => {
		const observedIndex = Math.round(e.target.scrollLeft / width)
		setObservedSlide(observedIndex)
		clearTimeout(independentTimeout.current)
		independentTimeout.current = setTimeout(() => {
			setTargetSlide(-1)
		}, 120)
	}

	useEffect(() => {
		if (targetSlide >= 0) {
			targetScroll.current = targetSlide * width
			containerRef.current.scrollTo({
				left: targetScroll.current,
				behavior: "smooth"
			})
		}
	}, [targetSlide, width])

	useEffect(() => {
		if (lastWidth.current !== width) {
			containerRef.current.scrollLeft = observedSlide * width
		}
		lastWidth.current = width
	}, [width, observedSlide])

	const slidePadding = 20
	const slideHeight = RATIOS[ratio] ? ((width - slidePadding * 2) * RATIOS[ratio]) : undefined

	return <div className={`Slides ${ratio}-ratio ${multipleSlides && "multiple"}`} ref={wrapper}>
		<div
			className="slides-container"
			onScroll={onScroll}
			ref={containerRef}
		>
			{ children.map((slide, i) => (
				<div className="slide" key={i} style={{ height: slideHeight }}>
					{ slide }
				</div>)
			)}
		</div>
		{ multipleSlides &&
			<div className="controls">
				<button className="directional icon-chevron-left" onClick={previousSlide} title={t("previous")} disabled={!canGoPrevious()} />
				{ children.map((_child, i) => <button key={`go-to-${i}`} className={`indicator ${observedSlide === i && "active"}`} onClick={() => setTargetSlide(i)} />)}
				<button className="directional icon-chevron-right" onClick={nextSlide} title={t("next")} disabled={!canGoNext()} />
			</div>
		}
	</div>
}

Slides.propTypes = {
	ratio: PropTypes.oneOf(["square", "wide", "tall", "auto"])
}

export default Slides