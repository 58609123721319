import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter, Redirect, Route, Switch } from "react-router-dom"
import { setStack, setStackCards, setStackHome } from "../actions"
import DetailsCard from "./Cards/DetailsCard"
import ItemListCard from "./Cards/ItemListCard"
import PricingListCard from "./Cards/PricingListCard"
import MusicAlbumCard from "./Cards/MusicAlbumCard"
import VideoCard from "./Cards/VideoCard"
import QRCodeCard from "./Cards/QRCodeCard"
import CardListCard from "./Cards/CardListCard"
import ImagesCard from "./Cards/ImagesCard"
import { axios_instance, isProduction } from "../util/server"
import "./StackViewer.scss"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { t } from "../intl"
import ContactCard from "./Cards/ContactCard"
import LandingCard from "./Cards/LandingCard"

const debugMessages = false

const cardTypes = {
	details: DetailsCard,
	itemList: ItemListCard,
	pricingList: PricingListCard,
	musicAlbum: MusicAlbumCard,
	video: VideoCard,
	qrCode: QRCodeCard,
	cardList: CardListCard,
	landing: LandingCard,
	images: ImagesCard,
	contact: ContactCard,
}

// Path to card:  /magicard?title=Magicard&body=Lul&image=https%3A%2F%2Fparticle-cards.s3.us-east-2.amazonaws.com%2Fuploads%2Fb70c7fd0-716d-4c37-b6aa-b0adde629b72%2F500x500bb.jpg
// http://localhost:3030/duckyStack/256cfbf31c3da67779300204e10052bf?body=Lul&image=https%3A%2F%2Fparticle-cards.s3.us-east-2.amazonaws.com%2Fuploads%2Fb70c7fd0-716d-4c37-b6aa-b0adde629b72%2F500x500bb.jpg

class StackViewer extends Component {
	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired,
		uiSize: PropTypes.string.isRequired,
		stack: PropTypes.object
	}

	constructor (props) {
		super(props)
		this.state = {
			stackId: props.match.params.stackId,
			cardAnimationGoBack: false,
			navigationBreadcrumbs: [],
			failedToLoadStack: false,
			waitingServer: false
		}
		this.onReceiveMessage = this.onReceiveMessage.bind(this)
	}

	refreshDocumentTitle () {
		const cardId = this.getRouteCardId()
		const card = this.getCard(cardId)
		if (card) {
			document.title = card.name
		}
	}

	componentDidMount () {
		window.addEventListener("message", this.onReceiveMessage, false)
		window.parent.postMessage({ type: "event", event: "mounted" }, "*")
		if (this.state.stackId && this.state.stackId !== "undefined") {
			this.setState({ waitingServer: true })
			axios_instance.get(`/stacks/${this.state.stackId}.json`)
				.then(response => {
					this.props.setStack(response.data.stack)
					window.parent.postMessage({ type: "event", event: "loaded" }, "*")
					this.refreshDocumentTitle()
					if (isProduction) {
						window.gtag('js', new Date());
						window.gtag('config', 'G-HS6ST5BZ2X');
					}
				})
				.catch(e => {
					console.error(e, e.response)
					this.setState({ failedToLoadStack: true })
				})
				.finally(() => {
					this.setState({ waitingServer: false })
				})
		}
	}

	componentDidUpdate (prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			window.parent.postMessage({ type: "event", event: "locationChange", location: this.props.location }, "*")
		}
		if (this.props.location.pathname !== prevProps.location.pathname && this.props.stack.cards.length > 0 && this.props.stack.home) {
			const cardId = this.getRouteCardId()
			this.refreshDocumentTitle()
			const followingClickedLink = this.props.location.pathname !== this.externallySetPathname
			if (followingClickedLink) {
				this.externallySetPathname = null
				setTimeout(() => {
					this.setState({ cardAnimationGoBack: false })
				}, this.getCardTransitionDuration() - 20)
				window.parent.postMessage({
					type: "event",
					event: "goToCard",
					cardId
				}, "*")
				if (!this.state.navigationBreadcrumbs.includes(this.props.location.pathname)) {
					let navigationBreadcrumbs = this.state.navigationBreadcrumbs.concat([this.props.location.pathname])
					this.setState({ navigationBreadcrumbs })
				}
			} else {
				this.setState({ navigationBreadcrumbs: [this.props.location.pathname] })
			}
			if (this.isCardHome(cardId)) {
				this.setState({ navigationBreadcrumbs: [] })
			}
		}
	}

	async onReceiveMessage (e) {
		if (debugMessages) console.log('(player) StackViewer onReceiveMessage', e.data)
		const { cards, home, currentCard } = e.data
		if (cards) {
			if (debugMessages) console.log(`(player) Updating cards (${cards.length})`)
			await this.props.setStackCards(cards)
		}
		if (home) {
			if (debugMessages) console.log(`(player) Updating home (${home})`)
			const differentHome = this.props.stack.home !== home
			await this.props.setStackHome(home)
			if (differentHome) {
				this.props.history.push(`/${this.state.stackId}`)
			}
		}
		if (currentCard && this.props.stack.home) {
			const targetPath = `/${this.state.stackId}` + (this.isCardHome(currentCard) ? "" : `/${currentCard}`)
			if (targetPath !== this.props.location.pathname) {
				if (debugMessages) console.log(`(player) currentCard triggered go to "${targetPath}"`)
				this.externallySetPathname = targetPath
				this.props.history.push(targetPath)
			}
		}
	}

	isCardHome (cardId) {
		return !cardId || this.props.stack.home === cardId
	}

	getRouteCardId (router = this.props) {
		return router.match.params.cardId || this.props.stack.home
	}

	getCard (cardId) {
		return this.props.stack.cards.find(cardData => cardData.id === cardId)
	}

	getCardComponent (card, router = this.props) {
		if (!card) return <div></div>
		const Component = cardTypes[card.type]
		if (!Component) return null
		const cardId = router.match.params.cardId
		return <Component
			key={`${router.match.params.stackId}-${cardId}`}
			type={card.type}
			data={card.data}
			name={card.name}
			urlParams={router.match.params}
			locationSearch={router.location.search}
			getCard={this.getCard.bind(this)}
			backwardAnimationIn={this.state.cardAnimationGoBack && this.getRouteCardId(router) !== cardId}
			isHome={this.isCardHome(cardId)}
		/>
	}

	renderContents (router) {
		const card = this.getCard(this.getRouteCardId(router))
		if (!card && this.props.stack.cards.length > 0 && this.props.stack.home) {
			return this.state.stackId ? <Redirect to={`/${this.state.stackId}`} /> : null
		}
		return this.getCardComponent(card, router)
	}

	waitStateChangeThenGoBack () {
		if (this.state.cardAnimationGoBack) {
			const { history } = this.props
			if (this.state.navigationBreadcrumbs.length > 0) {
				let navigationBreadcrumbs = this.state.navigationBreadcrumbs.concat()
				navigationBreadcrumbs.pop()
				this.setState({ navigationBreadcrumbs })
				history.push(navigationBreadcrumbs[navigationBreadcrumbs.length - 1] || `/${this.state.stackId}`)
			} else {
				history.push(this.goBackLink())
			}
		} else {
			requestAnimationFrame(() => this.waitStateChangeThenGoBack())
		}
	}

	onGoBackClick () {
		this.setState({ cardAnimationGoBack: true })
		this.waitStateChangeThenGoBack()
	}

	goBackLink () {
		if (this.isCardHome(this.getRouteCardId())) {
			return null
		}
		let urlParts = this.props.location.pathname.split('/')
		if (urlParts.length <= 2) {
			return null
		}
		urlParts.pop()
		return urlParts.join('/')
	}

	getCardTransitionDuration () {
		return this.props.uiSize === "large" ? 900 : 500
	}

	render () {
		if (!this.state.waitingServer && ((this.state.stackId && this.state.failedToLoadStack) || !this.props.stack.home)) {
			return <div className="full-screen-notice">
				<div className="title">{ t("stack_not_found") }</div>
				<a href="https://stacky.app/" className="go-stacky arrow-link">{ t("go_to_stacky") }</a>
			</div>
		}

		const backLink = this.goBackLink()
		const hasBackLink = Boolean(this.goBackLink())
		const cssTransitionClass = this.state.cardAnimationGoBack ? "card-backward" : "card-forward"
		const classNames = [
			"StackViewer",
			`${this.props.uiSize}-ui`
		]
		if (hasBackLink) classNames.push("with-top-navigation")
		const style = {
			"--card-transition-duration": `${this.getCardTransitionDuration()}ms`
		}
		return (
			<div className={classNames.join(" ")} style={style}>
				<header className="navigation">
					<CSSTransition
						in={hasBackLink}
						classNames="slide-left"
						timeout={this.getCardTransitionDuration()}
						mountOnEnter={true}
						unmountOnExit={true}
					>
						<div className="go-back" to={backLink} onClick={this.onGoBackClick.bind(this)}>
							<span className="icon icon-chevron-left"></span>
							<span>{ t("back") }</span>
						</div>
					</CSSTransition>
				</header>
				<main className="viewport">
					<TransitionGroup component={null} enter={true}>
						<CSSTransition
							key={this.props.location.key}
							classNames={cssTransitionClass}
							timeout={this.getCardTransitionDuration()}
						>
							<Switch location={this.props.location}>
								<Route path={[
									"/:stackId/:cardId",
									"/:stackId"
								]} children={router => this.renderContents(router)} />
							</Switch>
						</CSSTransition>
					</TransitionGroup>
				</main>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	uiSize: state.ui.size,
	stack: state.stack
})

const mapDispatchToProps = { setStack, setStackCards, setStackHome }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StackViewer))
