export default {
	back: "Volver",
	previous: "Anterior",
	next: "Siguiente",
	stack_not_found: "Este stack no pudo ser encontrado.",
	go_to_stacky: "Ir a Stacky",



	// Non-translatable.

	twitter: "Twitter",
	facebook: "Facebook",
	youtube: "YouTube",
	instagram: "Instagram",
	stack: "Stack",
	telegram: "Telegram",
	whatsapp: "WhatsApp",


	
	// Shared.

	phone: "Teléfono",
	main_phone: "Teléfono principal",
	second_phone: "Teléfono secundario",
	address: "Dirección",
	email: "Email",
	external_link: "Link externo",
	
	
	
	// Website.
	
	sub_logo_title: "Creá micro sitios.",
	turn_your: "Creá una versión digital de tu",
	into_cards: "en minutos",
	restaurant_menu: "Menú de restaurante",
	product_catalog: "Catálogo de productos",
	personal_card: "Tarjeta personal",
	product_instructions: "Manual",
	click_to_try_this_stack: "Click para probar este stack",

	scan_qr_code: "Escaneá el código QR para",
	see_menu: "ver el menú",
	see_catalog: "ver el catálogo",
	see_personal_card: "ver la tarjeta",
	see_instructions: "leer el manual",

	sign_up_or_log_in: "Crear cuenta o iniciar sesión",
	accounts_for_free: "Creá una cuenta gratis",

	creator_copy_1: "Editor fácil de usar.",
	creator_copy_2: "Creá tarjetas y conectalas.",
	creator_copy_3: "Vista previa en tiempo real.",
	creator_copy_mobile: "Edita en móbil y escritorio.",
	creator_copy_4: "Publicá y obtené un link y código QR para compartir instantáneamente.",

	for_developers: "Para desarrolladores",
	devs_main_title: "Front-end que no tenés que mantener.",
	devs_main_paragraph: "Tomá ventaja de la UI y UX de Stacky conectando tu back-end a tarjetas.",
	learn_more: "Conocé más"
}