import React from "react"
import Action from "../../GUI/Action"

export default function CallsToAction ({ actions }) {
	if (!actions || actions.length === 0) {
		return null
	}
	return <div className="cta floaty">
		{ actions.map((cta, i) => <Action key={i} {...cta.action}>
			{ cta.label }
		</Action> )}
	</div>
}